import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
	Button,
	Card,
	CardActionArea,
	CardContent,
	ListItemText,
	Skeleton,
	TableHead,
	Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faEye,
	faPenToSquare,
	faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { Add, Delete, Edit, Preview } from "@mui/icons-material";
import Confirmation from "../../components/Cards/Confirmation";
import CustomTablePaginationActions from "../../components/Tables/TablePaginationActions";
import trainerApi from "../../api/trainer";
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";
import { showError, showSuccess } from "../../App";
import profileApi from "../../api/profile";
import { convertFromSnake } from "../../services/commonUtils";
import { getTimeStamp } from "../../services/dateUtils";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
	"&:last-child td, &:last-child th": {
		border: 0,
	},
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

function createData(id, name, email, updated_at) {
	return { id, name, email, updated_at: new Date(updated_at) };
}
export default function SubmissionTable({ submissions, questions }) {
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [rows, setRows] = useState([]);
	const [cols, setCols] = useState([]);
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();
	const [selected, setSelected] = useState({});
	// const [data, setData] = useState(null);
	const [loading, setLoading] = useState(true);
	// State to store random widths
	const [randomWidths, setRandomWidths] = useState([]);

	useEffect(() => {
		// Generate 5 random widths when the component mounts
		const widths = Array.from(
			{ length: 5 },
			() => `${Math.floor(Math.random() * (90 - 10 + 1)) + 10}%`
		);
		setRandomWidths(widths);
	}, []);

	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	useEffect(() => {
		if (!submissions) return;
		setRows(
			submissions
				.map((item) => {
					let totalPoints = 0;
					for (let i = 0; i < questions.length; i++) {
						const question = questions[i];

						if (question.answer.correct === item.answers[i]) {
							totalPoints += parseInt(question.points);
						}
					}
					return {
						id: item.id,
						name: item.user.name,
						points: totalPoints,
						submitted_at: new Date(item.completed_at),
					};
				})
				.sort((a, b) => b.submitted_at - a.submitted_at)
		);

		setCols({
			name: "35%",
			points: "30%",
			submitted_at: "35%",
		});
		console.log(cols);
		setLoading(false);
	}, [submissions, questions]);

	return (
		<div className="w-full">
			{loading ? (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<StyledTableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-[40%]"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-[30%]"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-[30%]"
										/>
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{[...Array(5).keys()].map((index) => (
								<TableRow>
									<TableCell className="!py-3">
										<div className="flex flex-row gap-4 justify-end">
											<Skeleton
												variant="text"
												height={30}
												className="w-[40%]"
											/>
											<Skeleton
												variant="text"
												height={30}
												className="w-[30%]"
											/>
											<Skeleton
												variant="text"
												height={30}
												className="w-[30%]"
											/>
										</div>
									</TableCell>
								</TableRow>
							))}
						</TableBody>

						<TableFooter>
							<TableRow>
								<TableCell className="!py-3">
									<div className="flex flex-row gap-4 justify-end">
										<Skeleton
											variant="text"
											height={30}
											className="w-40"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-24"
										/>
										<Skeleton
											variant="text"
											height={30}
											className="w-12 md:w-32"
										/>
									</div>
								</TableCell>
							</TableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			) : (
				<TableContainer component={Paper}>
					<Table aria-label="custom pagination table">
						<TableHead>
							<TableRow>
								<StyledTableCell>
									<div className="flex flex-row w-full">
										<div
											style={{
												width: "40%",
											}}
										>
											Name
										</div>
										<div
											style={{
												width: "30%",
												textAlign: "center",
											}}
										>
											Points
										</div>
										<div
											style={{
												width: "30%",
												textAlign: "center",
											}}
										>
											Submitted At
										</div>
									</div>
								</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{(rowsPerPage > 0
								? rows.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
								  )
								: rows
							).map((row) => (
								<TableRow
									// hover
									// role="checkbox"
									// tabIndex={-1}
									key={row.id}
									// style={{
									// 	cursor: "pointer",
									// }}
								>
									<TableCell
									// onClick={() =>
									// 	navigate("/profiles/edit#" + row.id)
									// }
									>
										<div className="flex flex-row w-full">
											<div className={`truncate w-[40%]`}>
												<Typography noWrap>
													{row.name}
												</Typography>
											</div>
											<div
												className={`truncate w-[30%] text-center`}
											>
												<Typography noWrap>
													{row.points}
												</Typography>
											</div>
											<div
												className={`truncate w-[30%] text-center`}
											>
												<Typography noWrap>
													{getTimeStamp(
														row.submitted_at
													)}
												</Typography>
											</div>
										</div>
									</TableCell>
								</TableRow>
							))}
							{emptyRows > 0 && (
								<TableRow style={{ height: 57 * emptyRows }}>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
						<TableFooter>
							<StyledTableRow>
								<TablePagination
									rowsPerPageOptions={[
										5,
										10,
										25,
										{ label: "All", value: -1 },
									]}
									colSpan={3}
									count={rows.length}
									rowsPerPage={rowsPerPage}
									page={page}
									slotProps={{
										select: {
											inputProps: {
												"aria-label": "rows per page",
											},
											native: true,
										},
									}}
									onPageChange={handleChangePage}
									onRowsPerPageChange={
										handleChangeRowsPerPage
									}
									ActionsComponent={
										CustomTablePaginationActions
									}
								/>
							</StyledTableRow>
						</TableFooter>
					</Table>
				</TableContainer>
			)}
		</div>
	);
}
