import { Grid } from "@mui/material";
import React, { useState } from "react";

const CardContainer = (props) => {
	return (
		<Grid container spacing={4}>
			{props.children?.map((child, index) => (
				<Grid item xs={12} sm={6} md={12 / props.col} key={index}>
					{child}
				</Grid>
			))}
		</Grid>
	);
};

export default CardContainer;
