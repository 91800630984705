import React, { useContext, useState, useEffect } from "react";
// import AuthService from "../services/authService";
import {
	FormControl,
	InputLabel,
	OutlinedInput,
	Button,
	Box,
	TextField,
	Typography,
	Container,
	CssBaseline,
	Card,
	CardContent,
	InputAdornment,
	Avatar,
	Divider,
} from "@mui/material";
import packageInfo from "../../package.json"; // Adjust the path as necessary
import { GiDolphin } from "react-icons/gi";
import {
	Google as GoogleIcon,
	Facebook as FacebookIcon,
	LoginOutlined,
	EmojiEvents,
	Google,
	Facebook,
} from "@mui/icons-material"; // Import icons
import EyeIcon from "../components/Icons/EyeIcon";
import { useTheme } from "@emotion/react";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFish, faFishFins, faRobot } from "@fortawesome/free-solid-svg-icons";
import authService from "../services/auth";
// import { setLoading } from "./page";
import config from "../config/config";
import { faCirclePlay } from "@fortawesome/free-regular-svg-icons";
import { setLoading } from "../App";

const MuiPasswordField = (props) => {
	const [showPassword, setShowPassword] = useState(false);
	return (
		<FormControl fullWidth variant="outlined" size="small" required>
			<InputLabel htmlFor="outlined-adornment">Password</InputLabel>
			<OutlinedInput
				placeholder="••••••••"
				type={showPassword ? "text" : "password"}
				value={props.value}
				onChange={props.onChange}
				label="Password"
				size="small"
				fullWidth
				endAdornment={
					<EyeIcon
						isVisible={props.value.length > 0}
						showPassword={showPassword}
						setShowPassword={setShowPassword}
					/>
				}
			/>
		</FormControl>
	);
};

const Login = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [loggingIn, setLoggingIn] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoggingIn(true);
		const res = await authService.login({
			email,
			password,
		});
		if (res.success) {
			// do some delay
			setLoggingIn(false);
			navigate(config.loginRedirect);
		} else {
			setLoggingIn(false);
		}
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	const handleGuestEntry = () => {
		// Implement guest entry logic here
		// For example, you might set a guest flag in your app state
		// and redirect to the main content area
		navigate(config.loginRedirect);
	};

	return (
		<Container
			component="main"
			maxWidth="xs"
			sx={{
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				height: "100vh",
			}}
		>
			<CssBaseline />
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					width: "100%",
				}}
			>
				<div className="">
					{/* <div className="text-3xl font-bold font-logo text-[#444] dark:text-white flex items-center gap-1">
						<FontAwesomeIcon icon={faCirclePlay} />
						{packageInfo.name}
					</div> */}
					<img
						src="/opengraph-image.svg"
						alt="logo"
						className="h-14 md:h-16"
					/>
				</div>
				<Card
					sx={{ maxWidth: 400, width: "100%" }}
					className="rounded-lg"
				>
					<CardContent className="flex flex-col gap-4 !p-6">
						<Button
							variant="contained"
							color="primary"
							fullWidth
							onClick={() =>
								(window.location.href =
									config.authUrl +
									"/login/?next=" +
									config.baseUrl +
									config.loginRedirect)
							}
						>
							Login
						</Button>
						<Button
							variant="outlined"
							fullWidth
							onClick={() =>
								(window.location.href =
									config.authUrl +
									"/register/?next=" +
									config.baseUrl +
									config.loginRedirect)
							}
						>
							Create Account
						</Button>
						<Button
							variant="text"
							fullWidth
							onClick={handleGuestEntry}
						>
							Continue as Guest
						</Button>
					</CardContent>
				</Card>
				<div className="mt-4">
					<Typography style={{ color: "grey" }}>
						v{packageInfo.version}
					</Typography>
				</div>
			</Box>
		</Container>
	);
};

export default Login;
