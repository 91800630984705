import Api from "./base";

class ProfileApi extends Api {
	getAllProfiles = async () => {
		return await this.get("/users/");
	};
	getProfileById = async (id) => {
		return await this.get(`/users/${id}/`);
	};
	getProfileSchema = async () => {
		return await this.get("/users/schema/");
	};
	updateProfile = async (id, data) => {
		return await this.put(`/users/${id}/`, data);
	};
	deleteProfile = async (id) => {
		return await this.delete(`/users/${id}/`);
	};
	getMyProfile = async () => {
		return await this.get("/priyo-user-info/");
	};
	getUserProfile = async () => {
		return await this.get("/users/auth/");
	};
}

const profileApi = new ProfileApi();
export default profileApi;
