import React from "react";
import { Button, Typography, Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

// components/LoginPrompt.jsx

const LoginPrompt = ({ onLogin }) => {
	return (
		<Box className="mt-4 p-4 bg-gray-100 dark:bg-gray-900 rounded-lg text-center shadow-md border py-6 w-full">
			<LockIcon className="text-gray-500 dark:text-gray-400 mb-2" fontSize="large" />
			<h2 className="text-2xl font-bold text-slate-800">
				Want to participate in this quiz?
			</h2>
			<p className="text-sm text-gray-500">
				Log in to participate and win exciting prizes!
			</p>
			<Button variant="contained" color="primary" onClick={onLogin} startIcon={<LockIcon />} className="mt-2">
				Log In to participate
			</Button>
		</Box>
	);
};

export default LoginPrompt;
