import {
	Container,
	CssBaseline,
	useTheme,
	Box,
	Toolbar,
	Divider,
	Button,
	Avatar,
	IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DrawerBar from "../Sidebars/DrawerBar";
import LeftSidebar from "../Sidebars/LeftSidebar";
import Breadcrumb from "../Breadcrumb";
import ToggleMode from "../Buttons/ToggleButton";
import config from "../../config/config";
import profileApi from "../../api/profile";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
const drawerWidth = config.drawerWidth;
const PrimaryLayout = (props) => {
	const theme = useTheme();
	const [profile, setProfile] = useState();
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth();
	const [view, setView] = useState();
	const [searchParams] = useSearchParams();
	const fetchProfile = async () => {
		const response = await profileApi.getMyProfile();
		if (response.success) {
			setProfile(response.data.user.priyo_user.profile);
		}
	};

	useEffect(() => {
		if (isAuthenticated) {
			fetchProfile();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		if (searchParams.get("view")) {
			setView(searchParams.get("view"));
		}
	}, [searchParams]);

	return (
		<Box sx={{ display: "flex" }}>
			<CssBaseline />
			{view !== "app" && (
				<Box
					sx={{ width: { xs: "0px", md: `calc(${drawerWidth}px)` } }}
				>
					<LeftSidebar />
				</Box>
			)}

			<div className="w-full relative">
				{view !== "app" && (
					<>
						<Box
							className="fixed z-50 flex-col"
							sx={{
								backgroundColor:
									theme.palette.background.default,
								width: {
									xs: 0,
									md: `calc(100% - ${drawerWidth}px)`,
								},
								display: { xs: "none", md: "flex" },
							}}
						>
							<Toolbar className="flex flex-row items-center justify-between pr-3">
								<Breadcrumb />
								<div className="flex flex-row items-center">
									{isAuthenticated && (
										<IconButton>
											<Avatar
												alt={profile?.email ?? "Guest"}
												src={profile?.image}
												sx={{
													width: 30,
													height: 30,
												}}
												onClick={() => {
													window.location.href =
														config.authUrl;
												}}
												className="cursor-pointer"
											/>
										</IconButton>
									)}
									{/* <ToggleMode /> */}
								</div>
							</Toolbar>
							<Divider />
						</Box>
						<Toolbar />
					</>
				)}

				<div>{props.children}</div>
			</div>
		</Box>
	);
};

export default PrimaryLayout;
