import { Box, Button } from "@mui/material";
import FormsTable from "./FormsTable";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import AddButton from "../../components/Buttons/AddButton";
import quizApi from "../../api/quiz";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import profileApi from "../../api/profile";
import { showError } from "../../App";
import FormsGrid from "./FormsGrid";

export default function PriyoPlay() {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const createForm = async () => {
		setLoading(true);
		const res = await quizApi.createQuiz({
			title: "Untitled form",
			description: "Form description",
			start_time: null,
			end_time: null,
			is_published: false,
			thumbnail: null,
			// message: "",
			questions: [
				{
					title: "Untitled Question",
					answer: {
						type: "multiple",
						options: ["Option 1"],
						correct: null,
					},
					points: 0,
				},
			],
			required_fields: await fetchSchema(),
		});

		if (res.success) {
			navigate(`/priyo-play/edit#${res.data.id}`);
		}
		setLoading(false);
	};

	const fetchSchema = async () => {
		const res = await profileApi.getProfileSchema();
		if (res.success) {
			const tmp_schema = res.data;
			const initialNews = {};
			for (const key in tmp_schema) {
				if (tmp_schema[key].editable) {
					initialNews[key] = null;
				}
				tmp_schema[key]["selected"] = false;
			}
			return tmp_schema;
		} else {
			showError("Can't fetch schema");
		}
		return null;
	};

	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<FormsGrid />
		</Box>
	);
}
