import React, { useState } from "react";
import {
	TextField,
	Button,
	Box,
	Switch,
	FormControlLabel,
	useTheme,
	Typography,
	Card,
	Paper,
	IconButton,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from "@mui/material";
import config from "../../config.json";
import { LoadingButton } from "@mui/lab";
import { Add, Remove, Save } from "@mui/icons-material";
import { convertFromSnake } from "../../services/commonUtils";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
const JsonField = ({ name, value, onChange }) => {
	const [jsonValue, setJsonValue] = useState(JSON.stringify(value, null, 2));

	const handleChange = (e) => {
		setJsonValue(e.target.value);
		try {
			const parsedValue = JSON.parse(e.target.value);
			onChange({ target: { name, value: parsedValue } });
		} catch (error) {
			// If parsing fails, don't update the parent state
		}
	};

	return (
		<TextField
			fullWidth
			multiline
			rows={4}
			label={convertFromSnake(name)}
			value={jsonValue}
			onChange={handleChange}
			variant="outlined"
			error={!isValidJson(jsonValue)}
			helperText={isValidJson(jsonValue) ? "Valid JSON" : "Invalid JSON"}
		/>
	);
};

const isValidJson = (str) => {
	try {
		JSON.parse(str);
		return true;
	} catch (e) {
		return false;
	}
};

const ArrayField = ({ name, value, onChange, renderField, parentKey }) => {
	const handleElementChange = (index, newValue) => {
		const newArray = [...value];
		newArray[index] = newValue;
		onChange({ target: { name, value: newArray } }, false, parentKey);
	};

	const addElement = () => {
		onChange({ target: { name, value: [...value, ""] } }, false, parentKey);
	};

	const removeElement = (index) => {
		const newArray = value.filter((_, i) => i !== index);
		onChange({ target: { name, value: newArray } }, false, parentKey);
	};

	return (
		<Paper
			elevation={3}
			sx={{
				p: 2,
				mt: 2,
				mb: 2,
				width: "100%",
			}}
		>
			<Typography
				variant="subtitle1"
				sx={{ mb: 1, fontWeight: "bold", textAlign: "left" }}
			>
				{convertFromSnake(name)}:
			</Typography>
			{value.map((element, index) => (
				<Box
					key={index}
					sx={{ display: "flex", alignItems: "center", mb: 1 }}
				>
					<Box sx={{ flexGrow: 1 }}>
						{renderField(
							`${name}[${index}]`,
							element,
							true,
							name,
							(e) => handleElementChange(index, e.target.value)
						)}
					</Box>
					<IconButton
						onClick={() => removeElement(index)}
						size="small"
					>
						<Remove />
					</IconButton>
				</Box>
			))}
			<Button
				startIcon={<Add />}
				onClick={addElement}
				variant="outlined"
				size="small"
				sx={{ mt: 1 }}
			>
				Add Element
			</Button>
		</Paper>
	);
};

const GenericForm = ({ formData, setFormData, schema }) => {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	const handleChange = (e, isNested = false, parentKey = "") => {
		const { name, value, type, checked } = e.target;
		setFormData((prevData) => {
			if (isNested) {
				if (Array.isArray(prevData[parentKey])) {
					// Handle array update
					const newArray = [...prevData[parentKey]];
					const index = parseInt(name.match(/\[(\d+)\]/)[1], 10);
					newArray[index] = value;
					return {
						...prevData,
						[parentKey]: newArray,
					};
				} else {
					return {
						...prevData,
						[parentKey]: {
							...prevData[parentKey],
							[name]: type === "checkbox" ? checked : value,
						},
					};
				}
			} else {
				console.log(name);
				if (Array.isArray(prevData[name])) {
					// Handle array update
					const newArray = value;
					return {
						...prevData,
						[name]: newArray,
					};
				} else {
					return {
						...prevData,
						[name]: type === "checkbox" ? checked : value,
					};
				}
			}
		});
	};

	const renderField = (
		key,
		value,
		isNested = false,
		parentKey = "",
		onChangeOverride = null,
		type,
		disabled = false,
		required = false
	) => {
		// console.log(key, type);
		const onChange = onChangeOverride || handleChange;

		const formatDateTimeLocal = (dateString) => {
			const date = new Date(dateString);
			const pad = (num) => num.toString().padStart(2, "0");
			const year = date.getFullYear();
			const month = pad(date.getMonth() + 1);
			const day = pad(date.getDate());
			const hours = pad(date.getHours());
			const minutes = pad(date.getMinutes());
			return `${year}-${month}-${day}T${hours}:${minutes}`;
		};

		switch (type) {
			case "CharField":
				if (key === "gender") {
					return (
						<FormControl
							variant="outlined"
							fullWidth
							required={required}
							disabled={disabled}
							sx={{ textAlign: "left" }} // Left align the content
						>
							<InputLabel id="question-type-label">
								{convertFromSnake(key)}
							</InputLabel>
							<Select
								value={value}
								onChange={(e) =>
									onChange(e, isNested, parentKey)
								}
								label={convertFromSnake(key)}
								name={key}
							>
								<MenuItem value={"Male"}>Male</MenuItem>
								<MenuItem value={"Female"}>Female</MenuItem>
							</Select>
						</FormControl>
					);
				}
				return (
					<TextField
						fullWidth
						label={convertFromSnake(key)}
						name={key}
						value={value}
						onChange={(e) => onChange(e, isNested, parentKey)}
						variant="outlined"
						// margin="normal"
						disabled={disabled}
						required={required}
					/>
				);

			case "DateTimeField":
				return (
					<TextField
						fullWidth
						label={convertFromSnake(key)}
						name={key}
						value={formatDateTimeLocal(value)}
						onChange={(e) => onChange(e, isNested, parentKey)}
						variant="outlined"
						// margin="normal"
						disabled={disabled}
						type="datetime-local"
						required={required}
					/>
				);

			case "DateField":
				return (
					<LocalizationProvider dateAdapter={AdapterDayjs}>
						<DemoContainer
							components={["DatePicker"]}
							// sx={{ padding: 0 }}
						>
							<DatePicker
								label={convertFromSnake(key)}
								value={value ? dayjs(value) : null}
								onChange={(e) =>
									onChange(e, isNested, parentKey)
								}
								slotProps={{
									textField: {
										fullWidth: true,
									},
								}}
								disabled={disabled}
							/>
						</DemoContainer>
					</LocalizationProvider>
				);

			case "PositiveIntegerField":
			case "FloatField":
			case "BigAutoField":
				return (
					<TextField
						fullWidth
						label={convertFromSnake(key)}
						name={key}
						type="number"
						value={value}
						onChange={(e) => onChange(e, isNested, parentKey)}
						variant="outlined"
						// margin="normal"
						disabled={disabled}
						required={required}
					/>
				);
			case "BooleanField":
				return (
					<FormControlLabel
						control={
							<Switch
								checked={value}
								onChange={(e) =>
									onChange(e, isNested, parentKey)
								}
								name={key}
								color="primary"
							/>
						}
						label={convertFromSnake(key)}
						sx={{
							marginLeft: 0,
							marginRight: "auto",
							width: "100%",
							justifyContent: "flex-start",
						}}
						disabled={disabled}
						required={required}
					/>
				);
			case "JSONField":
				if (Array.isArray(value)) {
					return (
						<ArrayField
							name={key}
							value={value}
							onChange={onChange}
							renderField={renderField}
							parentKey={parentKey}
						/>
					);
				} else {
					return (
						<Paper
							elevation={3}
							sx={{
								p: 2,
								mt: 2,
								mb: 2,
								width: "100%",
							}}
						>
							<Typography
								variant="subtitle1"
								sx={{
									mb: 1,
									fontWeight: "bold",
									textAlign: "left",
								}}
							>
								{convertFromSnake(key)}:
							</Typography>
							<Box sx={{ pl: 2 }}>
								{Object.entries(value).map(
									([nestedKey, nestedValue]) => (
										<Box
											key={nestedKey}
											sx={{ width: "100%" }}
										>
											{renderField(
												nestedKey,
												nestedValue,
												true,
												key
											)}
										</Box>
									)
								)}
							</Box>
						</Paper>
					);
				}
			default:
				return (
					<JsonField
						name={key}
						value={value}
						onChange={(e) => onChange(e, isNested, parentKey)}
					/>
				);
		}
	};
	// console.log(schema);
	return (
		<div className="w-full flex-grow flex mx-auto ">
			<div className="flex flex-col space-y-3 w-full">
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						gap: 2,
						p: 3,
						borderRadius: 1,
					}}
				>
					{Object.entries(formData).map(([key, value]) => (
						<Box key={key}>
							{renderField(
								key,
								value,
								undefined,
								key,
								undefined,
								schema[key].type,
								schema[key].editable === false,
								schema[key].required
							)}
						</Box>
					))}
				</Box>
			</div>
		</div>
	);
};

export default GenericForm;
