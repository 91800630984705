import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./LeaderBoard.scss";
import TropyIcon from "../../components/Icons/TropyIcon";

function getOrdinalSuffix(num) {
	const remainder = num % 10;
	const remainder100 = num % 100;

	if (remainder === 1 && remainder100 !== 11) {
		return `${num}st`;
	} else if (remainder === 2 && remainder100 !== 12) {
		return `${num}nd`;
	} else if (remainder === 3 && remainder100 !== 13) {
		return `${num}rd`;
	} else {
		return `${num}th`;
	}
}

const Leaderboard = ({ form }) => {
	const [leaderboard, setLeaderboard] = useState(null);

	const fetchLeaderboard = async () => {
		setLeaderboard(() =>
			form.winners_participations.map((submission) => {
				let totalPoints = 0;
				for (let i = 0; i < form.questions.length; i++) {
					const question = form.questions[i];
					if (
						JSON.stringify(question.answer.correct) ===
						JSON.stringify(submission.answers[i])
					) {
						totalPoints += parseInt(question.points);
						console.log(totalPoints, i);
					}
				}
				return {
					email: submission.user.email,
					points: totalPoints,
					image: submission.user.image,
					name: submission.user.name,
				};
			})
		);
	};

	useEffect(() => {
		fetchLeaderboard();
	}, [form.winners]);

	return (
		<div className="flex flex-col gap-8 mt-10">
			<article class="leaderboard mx-auto shadow-lg">
				<header className="flex flex-row justify-between w-full items-center h-20 md:h-[100px]">
					<TropyIcon />
					<h1 class="leaderboard__title flex flex-col items-end">
						<span class="leaderboard__title--top text-right">
							Winners
						</span>
					</h1>
				</header>

				<main class="leaderboard__profiles">
					{leaderboard?.map((user, index) => (
						<article
							class="leaderboard__profile gap-2"
							onClick={() => {}}
						>
							<span
								class="text-black text-xl md:text-[34px] font-bold"
								// style={{
								// 	fontWeight: 700,
								// }}
							>
								{/* {user.points} */}
								{getOrdinalSuffix(index + 1)}
							</span>
							<div className="flex flex-row items-center gap-10 justify-between">
								<div className="flex flex-row w-full">
									<span
										class="text-black text-base md:text-xl truncate text-ellipsis font-semibold md:font-bold"
										// style={{
										// 	fontWeight: 500,
										// 	letterSpacing: "0.5px",
										// }}
									>
										{user.name ?? "Anonymous"}
									</span>
								</div>

								<img
									src={
										user.image
											? user.image
											: "https://robohash.org/" +
											  user.email
									}
									alt="Mark Zuckerberg"
									className="rounded-full object-cover aspect-square w-[40px] md:w-[60px] max-w-full"
									style={{
										boxShadow:
											"0 0 0 10px #ebeef3, 0 0 0 22px #f3f4f6",
									}}
								/>
							</div>
						</article>
					))}
				</main>
			</article>
		</div>
	);
};

export default Leaderboard;
