import Api from "./base";

class QuizApi extends Api {
	getAllQuizes = async () => {
		return await this.get("/quiz/");
	};
	getQuizById = async (id) => {
		return await this.get(`/quiz/${id}/`);
	};
	getQuizSchema = async () => {
		return await this.get("/quiz/schema/");
	};
	updateQuiz = async (id, data) => {
		return await this.put(`/quiz/${id}/`, data);
	};
	deleteQuiz = async (id) => {
		return await this.delete(`/quiz/${id}/`);
	};
	createQuiz = async (data) => {
		return await this.post("/quiz/", data);
	};
	getPublishedQuizes = async () => {
		return await this.get("/quiz/published/", undefined, true);
	};
	getAuthQuizes = async () => {
		return await this.get("/quiz/auth/all_quiz/");
	};
	getStartedQuizById = async (id) => {
		return await this.get(`/quiz/start/${id}/`, undefined, true);
	};
	getAuthQuizById = async (id) => {
		return await this.get(`/quiz/auth/start/${id}/`);
	};
	submitQuiz = async (id, answers, fields) => {
		return await this.post(`/participation/quiz/${id}/`, {
			answers,
			user_info_collect: fields,
		});
	};
	getMySubmissions = async () => {
		return await this.get(`/quiz/auth/my_quiz/`);
	};
}

const quizApi = new QuizApi();
export default quizApi;
