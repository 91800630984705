import Api from "./base";

class ClickApi extends Api {
	getUrl = async (uuid) => {
		return await this.get(`/redirects/${uuid}/`, undefined, true);
	};
}

const clickApi = new ClickApi();
export default clickApi;
