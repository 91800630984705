// import { showToast, showSuccess, showMessage } from "../App";
import authApi from "../api/auth";
import {
	addTokenToLocalStorage,
	removeTokenFromLocalStorage,
} from "../api/base";
import { showError } from "../App";

class AuthService {
	login = async (token) => {
		addTokenToLocalStorage(token);
	};
	logout = async () => {
		removeTokenFromLocalStorage();
	};
}

const authService = new AuthService();
export default authService;
