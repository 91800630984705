import { useEffect, useState } from "react";
import CardContainer from "../../components/Containers/CardContainer";
import quizApi from "../../api/quiz";
import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	CardActions,
	Button,
	Chip,
	Box,
	Container,
	Divider,
	useTheme,
} from "@mui/material";
import {
	Edit,
	Delete,
	AssignmentTurnedIn,
	Error,
	Input,
	Person,
	Close,
	AccessTime,
} from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TimerIcon from "@mui/icons-material/Timer";
import { useNavigate } from "react-router-dom";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BlockIcon from "@mui/icons-material/Block";
import Pluralize from "pluralize";
import { IoEnter } from "react-icons/io5";
import { format } from "date-fns";
import { LinearProgress, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import QuizCard from "../../components/Cards/QuizCard";
import { setLoading } from "../../App";
import { useAuth } from "../../contexts/AuthContext";

function CalendarMarkIcon() {
	const theme = useTheme();
	return (
		<svg
			fill="none"
			height="28"
			viewBox="0 0 28 28"
			width="28"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				clipRule="evenodd"
				d="M7.79167 3.47826C7.79167 3.21412 8.0062 3 8.27083 3H9.22917C9.4938 3 9.70833 3.21412 9.70833 3.47826V5.86957H19.2917V3.47826C19.2917 3.21412 19.5062 3 19.7708 3H20.7292C20.9938 3 21.2083 3.21412 21.2083 3.47826V5.86957H24.0833C25.1419 5.86957 26 6.72606 26 7.78261V21C26 19.4019 25.2503 17.9789 24.0833 17.0636V12.5652H4.91667V22.1304C4.91667 22.6587 5.34573 23.087 5.875 23.087H16.455C16.8043 23.8464 17.3383 24.5032 17.9995 25H4.91667C3.85812 25 3 24.1435 3 23.087V7.78261C3 6.72606 3.85812 5.86957 4.91667 5.86957H7.79167V3.47826ZM9.70833 7.78261H19.2917V8.73913C19.2917 9.00327 19.5062 9.21739 19.7708 9.21739H20.7292C20.9938 9.21739 21.2083 9.00327 21.2083 8.73913V7.78261H23.125C23.6543 7.78261 24.0833 8.21086 24.0833 8.73913V10.6522H4.91667V8.73913C4.91667 8.21086 5.34573 7.78261 5.875 7.78261H7.79167V8.73913C7.79167 9.00327 8.0062 9.21739 8.27083 9.21739H9.22917C9.4938 9.21739 9.70833 9.00327 9.70833 8.73913V7.78261Z"
				fill={theme.palette.mode === "dark" ? "white" : "black"}
				fillRule="evenodd"
			/>
			<path
				d="M24.0005 25C25.2147 24.0878 26 22.6356 26 21V23.087C26 24.1435 25.1419 25 24.0833 25H24.0005Z"
				fill={theme.palette.mode === "dark" ? "white" : "black"}
			/>
			<path
				clipRule="evenodd"
				d="M21 16.75C18.6528 16.75 16.75 18.6528 16.75 21C16.75 23.3472 18.6528 25.25 21 25.25C23.3472 25.25 25.25 23.3472 25.25 21C25.25 18.6528 23.3472 16.75 21 16.75ZM15.25 21C15.25 17.8244 17.8244 15.25 21 15.25C24.1756 15.25 26.75 17.8244 26.75 21C26.75 24.1756 24.1756 26.75 21 26.75C17.8244 26.75 15.25 24.1756 15.25 21Z"
				fill={theme.palette.mode === "dark" ? "white" : "black"}
				fillRule="evenodd"
			/>
			<path
				d="M18.5007 20.3792C18.6959 20.184 19.0125 20.184 19.2078 20.3792L20.6216 21.793C20.8168 21.9883 20.8168 22.3049 20.6216 22.5001C20.4263 22.6954 20.1097 22.6954 19.9145 22.5001L18.5007 21.0863C18.3054 20.8911 18.3054 20.5745 18.5007 20.3792Z"
				fill={theme.palette.mode === "dark" ? "white" : "black"}
			/>
			<path
				d="M23.3253 19.0976C23.13 18.9023 22.8135 18.9023 22.6182 19.0976L19.9228 21.793C19.7275 21.9883 19.7275 22.3049 19.9228 22.5001C20.118 22.6954 20.4346 22.6954 20.6299 22.5001L23.3253 19.8047C23.5206 19.6094 23.5206 19.2929 23.3253 19.0976Z"
				fill={theme.palette.mode === "dark" ? "white" : "black"}
			/>
		</svg>
	);
}

export default function FormsGrid() {
	const [currentTime, setCurrentTime] = useState(null);
	const [data, setData] = useState(null);
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth();
	const fetchData = async () => {
		setLoading(true);
		const response = isAuthenticated
			? await quizApi.getAuthQuizes()
			: await quizApi.getPublishedQuizes();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
			setCurrentTime(new Date(response.time));
		}
		setLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, [isAuthenticated]);

	const getStatusIcon = (quiz) => {
		if (!quiz.is_published) {
			return <BlockIcon />;
		} else if (!quiz.start_time) {
			return <Error />;
		} else if (currentTime < new Date(quiz.start_time)) {
			return <ScheduleIcon />;
		} else if (quiz.end_time && currentTime > new Date(quiz.end_time)) {
			return <EventBusyIcon />;
		} else {
			return <PlayCircleFilledIcon />;
		}
	};

	const getStatusText = (quiz) => {
		if (!quiz.is_published) {
			return "Unpublished";
		} else if (!quiz.start_time) {
			return "No Start Time Specified";
		} else if (currentTime < new Date(quiz.start_time)) {
			return "Upcoming";
		} else if (quiz.end_time && currentTime > new Date(quiz.end_time)) {
			return "Ended";
		} else {
			return "Running";
		}
	};

	return (
		<>
			{data?.filter(
				(quiz) =>
					quiz.start_time &&
					currentTime >= new Date(quiz.start_time) &&
					(!quiz.end_time || currentTime <= new Date(quiz.end_time))
			).length > 0 && (
				<Container maxWidth={"lg"}>
					<Typography variant="h4" gutterBottom align="left">
						Running Quizes
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<CardContainer col={3}>
						{data
							?.filter(
								(quiz) =>
									quiz.start_time &&
									currentTime >= new Date(quiz.start_time) &&
									(!quiz.end_time ||
										currentTime <= new Date(quiz.end_time))
							)
							.map((quiz) => (
								<QuizCard
									quiz={quiz}
									currentTime={currentTime}
									hasParticipated={quiz.user_participated}
								/>
							))}
					</CardContainer>
				</Container>
			)}

			{data?.filter(
				(quiz) =>
					quiz.start_time && currentTime < new Date(quiz.start_time)
			).length > 0 && (
				<Container maxWidth={"lg"}>
					<Typography variant="h4" gutterBottom align="left">
						Upcoming Quizes
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<CardContainer col={3}>
						{data
							?.filter(
								(quiz) =>
									quiz.start_time &&
									currentTime < new Date(quiz.start_time)
							)
							.map((quiz) => (
								<QuizCard
									quiz={quiz}
									currentTime={currentTime}
									hasParticipated={quiz.user_participated}
								/>
							))}
					</CardContainer>
				</Container>
			)}

			{data?.filter(
				(quiz) => quiz.end_time && currentTime > new Date(quiz.end_time)
			).length > 0 && (
				<Container maxWidth={"lg"}>
					<Typography variant="h4" gutterBottom align="left">
						Ended Quizes
					</Typography>
					<Divider sx={{ mb: 2 }} />
					<CardContainer col={3}>
						{data
							?.filter(
								(quiz) =>
									quiz.end_time &&
									currentTime > new Date(quiz.end_time)
							)
							.map((quiz) => (
								<QuizCard
									quiz={quiz}
									currentTime={currentTime}
									hasParticipated={quiz.user_participated}
								/>
							))}
					</CardContainer>
				</Container>
			)}
		</>
	);
}
