import React, { useEffect, useState } from "react";
import {
	Box,
	Typography,
	Button,
	Card,
	Radio,
	CardContent,
	Checkbox,
	LinearProgress,
} from "@mui/material";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { TextField, IconButton } from "@mui/material";
import {
	ArrowForward,
	Check,
	CheckCircle,
	Clear,
	EmojiEvents,
	KeyboardDoubleArrowLeft,
	KeyboardDoubleArrowRight,
	NextPlan,
	Save,
	Send,
} from "@mui/icons-material";
import quizApi from "../../api/quiz";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CountdownTimer from "./Timer";
import { useAuth } from "../../contexts/AuthContext";
import LoginPrompt from "./LoginPrompt";
import config from "../../config/config";
import { setLoading, showError, showSuccess } from "../../App";
import { useSearchParams } from "react-router-dom";
import GenericForm from "../../components/Forms/GenericForm";
import { LoadingButton } from "@mui/lab";
import profileApi from "../../api/profile";
import { Helmet } from "react-helmet";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import Leaderboard from "./LeaderBoard";

function QuestionsTab({
	form,
	setForm,
	mode,
	handleSubmit,
	onPrev,
	answers,
	setAnswers,
	currentTime,
}) {
	const { isAuthenticated } = useAuth();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const getProperQuestTypeName = (value) => {
		if (value === "multiple") {
			return "MCQ";
		}
		if (value === "checkbox") {
			return "Multiple Answers";
		}
		if (value === "short") {
			return "Short Answer";
		}
		if (value === "paragraph") {
			return "Paragraph";
		}
		if (value === "dropdown") {
			return "Dropdown";
		}
	};
	useEffect(() => {
		console.log("Answers: ", answers);
	}, [answers]);

	const onSubmit = async (answers) => {
		setIsSubmitting(true);
		await handleSubmit(answers);
		setIsSubmitting(false);
	};
	return (
		answers && (
			<div className="flex flex-col items-center gap-6 w-full">
				{!isAuthenticated &&
					form.start_time &&
					currentTime > new Date(form.start_time) &&
					(!form.end_time ||
						currentTime <= new Date(form.end_time)) && (
						<LoginPrompt
							// onLogin={() => navigate(config.logoutRedirect)}
							onLogin={() => {
								window.location.href =
									config.authUrl +
									"/login/?next=" +
									window.location.href;
							}}
						/>
					)}
				{form.questions.map((question, i) => (
					<div
						className="w-full border shadow-md p-4 rounded-md"
						key={i}
					>
						<div className="flex flex-col">
							{/* <div className="flex items-end">
								<p className="text-left text-xs font-semibold bg-gray-200 rounded px-2 py-1 mb-4">
									{getProperQuestTypeName(
										question.answer.type
									)}
								</p>
							</div> */}
							<div className="flex flex-col md:flex-row items-start justify-between mb-4 gap-4">
								<p
									className="text-left px-1 font-semibold"
									value={question.title}
									onChange={(e) => {
										const newQuestions = [
											...form.questions,
										];
										newQuestions[i].title = e.target.value;
										setForm({
											...form,
											questions: newQuestions,
										});
									}}
								>
									{question.title}
								</p>
								<p
									className={`md:w-24 ml-auto px-4 py-2 text-sm font-semibold rounded shadow ${
										(form.end_time &&
											currentTime <
												new Date(form.end_time)) ||
										!form.submissions
											? "bg-primary-500"
											: JSON.stringify(
													form.submissions.answers[i]
											  ) ===
											  JSON.stringify(
													question.answer.correct
											  )
											? "bg-green-500"
											: "bg-red-500"
									} `}
									noWrap
								>
									{question.points + " points"}
								</p>
							</div>
							<div className="space-y-2">
								{
									// Multiple choice question
									question.answer.type === "multiple" ? (
										<div className="flex flex-col items-start w-full gap-4">
											{question.answer.options.map(
												(option, j) => (
													<div
														key={j}
														className="flex flex-row items-center w-full gap-2"
													>
														{/* <p
															className={`w-full text-left px-6 py-4 rounded border border-gray-200 ${
																!(
																	mode !==
																	"edit"
																) &&
																"hover:border-blue-500 cursor-pointer"
															}
														${answers[i] === j && "bg-blue-500 text-white"} 
														${
															mode === "ended" &&
															question.answer
																.correct ===
																j &&
															"bg-green-500 text-white"
														}
														${
															mode === "ended" &&
															question.answer
																.correct !==
																j &&
															answers[i] === j &&
															"bg-red-500 text-white"
														}
														`}
															onClick={() => {
																if (
																	mode !==
																	"edit"
																) {
																	return;
																}
																const newAnswers =
																	[
																		...answers,
																	];
																newAnswers[i] =
																	j;
																setAnswers(
																	newAnswers
																);
															}}
														>
															{option}
														</p> */}
														<Radio
															checked={
																answers[i] === j
															}
															onChange={() => {
																const newAnswers =
																	[
																		...answers,
																	];
																newAnswers[i] =
																	j;
																setAnswers(
																	newAnswers
																);
															}}
															value={j}
															name="radio-buttons"
															disabled={
																mode !== "edit"
															}
														/>{" "}
														<p
															fullWidth
															variant="body1"
															align="left"
														>
															{option}
														</p>
														{mode === "ended" &&
															question.answer
																.correct ===
																j && (
																<Check color="success" />
															)}
														{mode === "ended" &&
															question.answer
																.correct !==
																j &&
															answers[i] ===
																j && (
																<Clear color="error" />
															)}
													</div>
												)
											)}
										</div>
									) : question.answer.type === "checkbox" ? (
										<div className="flex flex-col items-start w-full gap-4">
											{question.answer.options.map(
												(option, j) => (
													<Box
														key={j}
														className="flex flex-row items-center w-full gap-2"
													>
														{/* <p
															className={`w-full text-left px-6 py-4 rounded border border-gray-200 ${
																!(
																	mode !==
																	"edit"
																) &&
																"hover:border-blue-500 cursor-pointer"
															}
																${answers[i].includes(j) && "bg-blue-500 text-white"} 
																${
																	mode ===
																		"ended" &&
																	question.answer.correct.includes(
																		j
																	) &&
																	"bg-green-500 text-white"
																}
																${
																	mode ===
																		"ended" &&
																	!question.answer.correct.includes(
																		j
																	) &&
																	answers[
																		i
																	].includes(
																		j
																	) &&
																	"bg-red-500 text-white"
																}
																`}
															onClick={() => {
																if (
																	mode !==
																	"edit"
																) {
																	return;
																}
																const newAnswers =
																	[
																		...answers,
																	];
																const answerIndex =
																	newAnswers[
																		i
																	].indexOf(
																		j
																	);
																if (
																	answerIndex !==
																	-1
																) {
																	newAnswers[
																		i
																	].splice(
																		answerIndex,
																		1
																	);
																} else {
																	newAnswers[
																		i
																	].push(j);
																	newAnswers[
																		i
																	].sort(
																		(
																			a,
																			b
																		) =>
																			a -
																			b
																	);
																}
																setAnswers(
																	newAnswers
																);
															}}
														>
															{option}
														</p> */}
														<Checkbox
															disabled={
																mode !== "edit"
															}
															checked={answers[
																i
															].includes(j)}
															onChange={() => {
																const newAnswers =
																	[
																		...answers,
																	];
																const answerIndex =
																	newAnswers[
																		i
																	].indexOf(
																		j
																	);

																if (
																	answerIndex !==
																	-1
																) {
																	newAnswers[
																		i
																	].splice(
																		answerIndex,
																		1
																	);
																} else {
																	newAnswers[
																		i
																	].push(j);
																	newAnswers[
																		i
																	].sort(
																		(
																			a,
																			b
																		) =>
																			a -
																			b
																	);
																}

																setAnswers(
																	newAnswers
																);
															}}
															value={j}
														/>
														<Typography
															fullWidth
															variant="body1"
															align="left"
														>
															{option}
														</Typography>
														{mode === "ended" &&
															question.answer.correct.includes(
																j
															) && (
																<Check color="success" />
															)}
														{mode === "ended" &&
															!question.answer.correct.includes(
																j
															) &&
															answers[i].includes(
																j
															) && (
																<Clear color="error" />
															)}
													</Box>
												)
											)}
										</div>
									) : question.answer.type === "short" ? (
										<input
											disabled={mode !== "edit"}
											className="border border-gray-300 rounded-md px-4 py-2 w-full"
											placeholder="Answer"
											value={answers[i]}
											onChange={(e) => {
												const newAnswers = [...answers];
												newAnswers[i] = e.target.value;
												setAnswers(newAnswers);
											}}
											fullWidth
											multiline
										/>
									) : question.answer.type === "paragraph" ? (
										<textarea
											disabled={mode !== "edit"}
											className="border border-gray-300 rounded-md px-4 py-2 w-full"
											placeholder="Answer"
											value={answers[i]}
											onChange={(e) => {
												const newAnswers = [...answers];
												newAnswers[i] = e.target.value;
												setAnswers(newAnswers);
											}}
											fullWidth
											multiline
										/>
									) : question.answer.type === "dropdown" ? (
										<div className="flex flex-row items-center w-full gap-2 justify-start">
											<select
												disabled={mode !== "edit"}
												className="border border-gray-300 rounded-md px-4 py-2 w-full"
												value={answers[i] ?? -1}
												onChange={(e) => {
													const newAnswers = [
														...answers,
													];
													newAnswers[i] =
														e.target.value;
													setAnswers(newAnswers);
												}}
											>
												{question.answer.options.map(
													(option, index) => (
														<option
															value={index}
															key={index}
														>
															{option}
														</option>
													)
												)}
											</select>

											{mode === "ended" &&
											question.answer.correct ===
												answers[i] ? (
												<Check color="success" />
											) : (
												<Clear color="error" />
											)}
										</div>
									) : (
										<></>
									)
								}

								{mode === "ended" && question.explanation && (
									<div className="mt-2 flex flex-col gap-1">
										<Typography variant="h6" align="left">
											Explanation
										</Typography>
										<Typography
											variant="body1"
											placeholder="Explanation"
											// value={question.explanation}
											fullWidth
											sx={{
												padding: "8px", // Adjust padding as needed
												whiteSpace: "pre-line",
												border: "1px solid",
											}}
											disabled={mode !== "edit"}
											align="left"
											className="bg-gray-200"
										>
											{question.explanation}
										</Typography>
									</div>
								)}
							</div>
						</div>
					</div>
				))}
				{isAuthenticated && (
					<div className="w-full flex justify-end">
						{/* <Button
							variant="contained"
							color="primary"
							startIcon={<KeyboardDoubleArrowLeft />}
							onClick={() => onPrev()}
						>
							Previous
						</Button> */}
						{mode === "edit" && (
							<button
								className="w-full bg-blue-500 text-white px-4 py-2 shadow-md hover:bg-blue-600"
								onClick={() => onSubmit(answers)}
								disabled={mode !== "edit"}
							>
								Submit
							</button>
						)}
					</div>
				)}
			</div>
		)
	);
}

function UserInfoTab({ form, setForm, onNext, fields, setFields, profile }) {
	useEffect(() => {
		if (Object.keys(fields).length === 0) {
			const newFields = {};
			for (const key in form.required_fields) {
				if (form.required_fields[key].required) {
					// newFields[key] = null;
					newFields[key] = profile[key] || null;
				}
			}
			setFields(newFields);
		}
	}, [form.required_fields, profile]);

	return (
		<div className="flex flex-col items-center gap-6 w-full">
			<form
				onSubmit={() => {
					onNext();
				}}
				className="w-full border p-4 rounded-md shadow-md"
			>
				<h2 className="text-2xl font-bold text-blue-500">
					User Details
				</h2>
				<p className="text-gray-500">
					Please fill in the following details to continue
				</p>
				{Object.keys(fields).length > 0 && (
					<GenericForm
						formData={fields}
						setFormData={setFields}
						schema={form.required_fields}
					/>
				)}
				<button className="w-full px-4 py-2 bg-blue-500 text-md text-white font-semibold shadow hover:bg-blue-600">
					Participate
					<KeyboardDoubleArrowRight />
				</button>
			</form>
		</div>
	);
}

function deepCopy(obj) {
	return JSON.parse(JSON.stringify(obj));
}

const ExpiredNotice = (props) => {
	return (
		<div className="flex justify-center items-center">
			<div className="bg-gradient-to-r from-red-500 to-pink-500 rounded-lg shadow-lg overflow-hidden">
				<div className="px-4 py-3 bg-white bg-opacity-20 backdrop-blur-lg">
					<span className="text-2xl font-bold text-white drop-shadow-md">
						{props.msg}
					</span>
				</div>
			</div>
		</div>
	);
};

export default function FormPreview() {
	const [value, setValue] = useState(0);
	const [form, setForm] = useState();
	const [savedForm, setSavedForm] = useState();
	const [saving, setSaving] = useState(false);
	const location = useLocation();
	const { id } = useParams();
	const [currentTime, setCurrentTime] = useState(null);
	const [searchParams] = useSearchParams();
	const [activeStep, setActiveStep] = useState(-1);
	const { isAuthenticated } = useAuth();
	const [fields, setFields] = useState({});
	const [profile, setProfile] = useState({});
	const [answers, setAnswers] = useState();
	// console.log("Answers: ", answers);

	const fetchForm = async () => {
		setLoading(true);
		const res = isAuthenticated
			? await quizApi.getAuthQuizById(id)
			: await quizApi.getStartedQuizById(id);
		if (res) {
			setCurrentTime(res.time);
			if (res.success) {
				setForm(deepCopy(res.data));
				setSavedForm(deepCopy(res.data));

				if (res.data.submissions) {
					setAnswers(deepCopy(res.data.submissions.answers));
				} else if (res.data.questions) {
					const newAnswers = [];
					res.data.questions.forEach((question) => {
						if (question.answer.type === "checkbox") {
							newAnswers.push([]);
						} else {
							newAnswers.push(null);
						}
					});
					setAnswers(newAnswers);
				}
			}
		}
		setLoading(false);
	};

	useEffect(() => {
		if (isAuthenticated && form) {
			if (!form.end_time || currentTime <= new Date(form.end_time)) {
				if (form.submissions) {
					setActiveStep(1);
				} else {
					if (searchParams.get("view") === "app") setActiveStep(1);
					else setActiveStep(0);
				}
			}
		}
	}, [isAuthenticated, form]);

	useEffect(() => {
		if (form?.start_time && currentTime >= new Date(form.start_time)) {
			if (activeStep === -1) {
				if (
					searchParams.get("view") === "app" ||
					currentTime >= new Date(form.end_time) ||
					!isAuthenticated
				)
					setActiveStep(1);
				else setActiveStep(0);
			} else if (activeStep === 0) {
				if (currentTime >= new Date(form.end_time)) {
					setActiveStep(1);
				}
			}
		}
	}, [currentTime]);

	const fetchProfile = async () => {
		const res = await profileApi.getUserProfile();
		if (res.success) {
			setProfile(res.data);
		}
	};

	useEffect(() => {
		if (id) {
			fetchForm();
		}
	}, [id, isAuthenticated]);

	useEffect(() => {
		if (isAuthenticated) {
			fetchProfile();
		}
	}, [isAuthenticated]);

	useEffect(() => {
		console.log("Updating Saved Form: ", savedForm);
	}, [savedForm]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handleSubmit = async (answers) => {
		setSaving(true);
		const res = await quizApi.submitQuiz(form.id, answers, fields);
		setCurrentTime(res.time);
		if (res.success) {
			setForm((prev) => ({
				...prev,
				submissions: res.data,
			}));
			showSuccess("Quiz submitted successfully");
		} else {
			showError("Failed to submit quiz");
		}
		setSaving(false);
	};

	const calculateTotalPoints = (form) => {
		let totalPoints = 0;
		for (let i = 0; i < form.questions.length; i++) {
			const question = form.questions[i];
			if (
				JSON.stringify(question.answer.correct) ===
				JSON.stringify(form.submissions.answers[i])
			) {
				totalPoints += parseInt(question.points);
			}
		}
		return totalPoints;
	};

	const calculateMaxPoints = (form) => {
		let totalPoints = 0;
		for (let i = 0; i < form.questions.length; i++) {
			const question = form.questions[i];
			totalPoints += parseInt(question.points);
		}
		return totalPoints;
	};

	return (
		form && (
			<>
				<Helmet>
					<title>{form.title + " | Priyo Quiz"}</title>
					<meta name="description" content={form.description} />
					<meta property="og:image" content={form.thumbnail} />
				</Helmet>
				<div className="max-w-4xl mx-auto font-sans relative">
					{form.thumbnail && (
						<img
							src={form.thumbnail}
							alt={form.title}
							className="w-full h-auto aspect-video object-cover"
						/>
					)}
				</div>
				<div className="max-w-4xl mx-auto p-6 font-sans relative">
					{/* Add Thumbnail Image */}

					<div className="flex items-center mb-2">
						<Typography variant="h4">{form.title}</Typography>
					</div>

					<div className="flex mb-4">
						<Typography
							variant="body2"
							sx={{
								whiteSpace: "pre-line",
							}}
							align="left"
						>
							{form.description}
						</Typography>
					</div>

					{
						<div className="mb-6">
							{form.start_time !== null &&
							form.end_time !== null ? (
								new Date() < new Date(form.start_time) ? (
									<>
										<ExpiredNotice msg="Quiz starts in" />
										<CountdownTimer
											targetDate={form.start_time}
											flag={"start"}
											currentTime={currentTime}
											setCurrentTime={setCurrentTime}
										/>
									</>
								) : (
									<CountdownTimer
										targetDate={form.end_time}
										flag={"end"}
										currentTime={currentTime}
										setCurrentTime={setCurrentTime}
									/>
								)
							) : (
								<div />
							)}
						</div>
					}

					{form.end_time &&
						currentTime > new Date(form.end_time) &&
						form.winners.length > 0 && (
							<Box className="mb-6">
								<Leaderboard form={form} />
							</Box>
						)}

					{form.end_time &&
						currentTime > new Date(form.end_time) &&
						form.submissions && (
							<Card
								sx={{
									mb: 3,
								}}
							>
								<CardContent>
									<Box
										display="flex"
										alignItems="center"
										marginBottom={1}
									>
										<EmojiEvents
											sx={{
												color: "#ffd700",
												marginRight: 1,
												fontSize: 28,
											}}
										/>
										<Typography
											variant="h6"
											component="div"
											fontWeight="bold"
										>
											Your Result
										</Typography>
									</Box>
									<Box
										display="flex"
										justifyContent="space-between"
										alignItems="center"
									>
										<Typography variant="body1">
											Total Points:
										</Typography>
										<Typography
											variant="h5"
											component="div"
											fontWeight="bold"
											color="primary"
										>
											{calculateTotalPoints(form)} /{" "}
											{calculateMaxPoints(form)}
										</Typography>
									</Box>
									<Box sx={{ width: "100%", marginTop: 1 }}>
										<LinearProgress
											variant="determinate"
											value={
												(calculateTotalPoints(form) /
													calculateMaxPoints(form)) *
												100
											}
											sx={{ height: 10, borderRadius: 5 }}
										/>
									</Box>
								</CardContent>
							</Card>
						)}
					{form.questions && activeStep === 1 && (
						<QuestionsTab
							{...{
								form,
								setForm,
								handleSubmit,
								answers,
								setAnswers,
								currentTime,
							}}
							mode={
								form.end_time &&
								currentTime > new Date(form.end_time)
									? "ended"
									: form.start_time &&
									  currentTime > new Date(form.start_time) &&
									  !form.submissions &&
									  isAuthenticated
									? "edit"
									: "view"
							}
							onPrev={() => setActiveStep(0)}
						/>
					)}

					{form.required_fields && activeStep === 0 && (
						<UserInfoTab
							{...{
								form,
								setForm,
								fields,
								setFields,
								profile,
							}}
							onNext={() => setActiveStep(1)}
						/>
					)}
				</div>
			</>
		)
	);
}
