import { Box, Container, Divider, Typography } from "@mui/material";
import config from "../../config/config";
import CardContainer from "../../components/Containers/CardContainer";
import { useEffect, useState } from "react";
import QuizCard from "../../components/Cards/QuizCard";
import quizApi from "../../api/quiz";
import { setLoading } from "../../App";

export default function Submissions() {
	const [currentTime, setCurrentTime] = useState(null);
	const [data, setData] = useState(null);
	const fetchData = async () => {
		setLoading(true);
		const response = await quizApi.getMySubmissions();
		if (response.success) {
			console.log(response.data);
			setData(response.data);
			setCurrentTime(new Date(response.time));
		}
		setLoading(false);
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<Box className="p-5 mx-auto flex items-center flex-col gap-10 w-full">
			<Container maxWidth={"lg"}>
				<Typography variant="h4" gutterBottom align="left">
					My Submissions
				</Typography>
				<Divider sx={{ mb: 2 }} />
				<CardContainer col={3}>
					{data?.map((quiz) => (
						<QuizCard quiz={quiz} currentTime={currentTime} />
					))}
				</CardContainer>
			</Container>
		</Box>
	);
}
