import axios from "axios";
import { API_BASE_URL, getTokenFromLocalStorage } from "./base";
import Api from "./base";
import config from "../config/config";
class AuthApi extends Api {
	login = async (data) => {
		try {
			let res = await axios.post(API_BASE_URL + "/login/", data);
			return {
				success: true,
				data: res.data,
			};
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return err.response.data;
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	logout = async () => {
		return await this.post("/logout");
	};

	updateMobile = async (mobile) => {
		try {
			const res = await axios.post(
				config.authUrl + "/auth/api/v1/update-email-phone/",
				{
					mobile: mobile,
				},
				{
					headers: {
						Authorization: "token " + getTokenFromLocalStorage(), // Replace 'token' with your actual token variable
					},
				}
			);
			return res.data;
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return err.response.data;
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};

	verifyMobile = async (mobile, code) => {
		try {
			const res = await axios.post(
				config.authUrl + "/auth/api/v1/update-email-phone/",
				{
					mobile: mobile,
					code: code,
				},
				{
					headers: {
						Authorization: "token " + getTokenFromLocalStorage(), // Replace 'token' with your actual token variable
					},
				}
			);
			return res.data;
		} catch (err) {
			if (err.hasOwnProperty("response")) {
				return err.response.data;
			} else {
				return {
					success: false,
					error: "Can't connect to server",
				};
			}
		}
	};
}
const authApi = new AuthApi();
export default authApi;
