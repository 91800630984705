import "./App.css";
import Login from "./pages/Login";
import { AppBar, Box, CssBaseline, Toolbar, createTheme } from "@mui/material";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
	Outlet,
} from "react-router-dom";
import ToggleMode from "./components/Buttons/ToggleButton";
import PrimaryLayout from "./components/Layouts/PrimaryLayout";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import config from "./config";
import Home from "./pages/Home";
import { getTokenFromLocalStorage, isTokenValid } from "./api/base";
import Storage from "./pages/Storage";
import Profiles from "./pages/Profiles";
import Advertisement from "./pages/Advertisement";
import Notification from "./pages/Notification";
import AddExcel from "./pages/Storage/AddExcel";
import EditProfilePage from "./pages/Profiles/EditProfilePage";
import News from "./pages/News";
import AddNews from "./pages/News/AddNews";
import EditNews from "./pages/News/EditNews";
import PreviewProfile from "./pages/Profiles/PreviewProfile";
import PriyoForm from "./pages/PriyoForm";
import AddForm from "./pages/PriyoForm/AddForm";
import FormDesigner from "./pages/PriyoPlay/FormDesigner";
import PriyoPlay from "./pages/PriyoPlay";
import Email from "./pages/Email";
import { useEffect, useState } from "react";
import authService from "./services/auth";
import FormPreview from "./pages/PriyoPlay/FormPreview";
import { useAuth } from "./contexts/AuthContext";
import Submissions from "./pages/Submissions";
import PhoneVerification from "./pages/Auth/PhoneVerification";
import AppDownloadPage from "./pages/Download";
import Click from "./pages/Click";
export const showToast = (message, type) => {
	console.log(message, type);
	if (type === "success") toast.success(message, {});
	else if (type === "error") toast.error(message, {});
	else {
		toast.dark(message, {});
	}
};
export const showSuccess = (message) => {
	showToast(message, "success");
};
export const showError = (message) => {
	showToast(message, "error");
};
export const showMessage = (message, res) => {
	showToast(message);
};

const Protected = () => {
	const { isAuthenticated } = useAuth();
	return isAuthenticated ? (
		<PrimaryLayout>
			<Outlet />
		</PrimaryLayout>
	) : (
		<Navigate to={"/login"} />
	);
};

const Public = () => {
	const { isAuthenticated } = useAuth();
	return isAuthenticated ? (
		<Navigate to={"/"} />
	) : (
		<>
			<Toolbar className="fixed right-0 top-0 ml-auto flex flex-row justify-end p-3 items-center gap-4 z-100">
				{/* <ToggleMode /> */}
			</Toolbar>
			<Outlet />
		</>
	);
};

const Guest = () => {
	return (
		<PrimaryLayout>
			<Outlet />
		</PrimaryLayout>
	);
};

var setLoading;
function App() {
	const [loading, setL] = useState(true);
	setLoading = setL;
	return (
		<>
			{loading ? (
				<Box
					className="fixed top-0 left-0 w-full h-full flex justify-center items-center"
					sx={{
						zIndex: 9999,
						backgroundColor: "background.paper",
					}}
				>
					<div className=" border-solid border-black dark:border-gray-800 rounded-full  border-t-white dark:border-t-pink-600 h-10 w-10 md:w-16 md:h-16 border-[4px] md:border-[6px] border-t-[6px] md:border-t-[8px] animate-spin"></div>
				</Box>
			) : (
				<></>
			)}
			<ToastContainer
				style={{ width: "270px" }}
				position="top-right"
				theme="colored"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			<Router>
				<div className="App h-full">
					<div className="content">
						<Routes>
							<Route element={<Public />}>
								<Route path="/login" element={<Login />} />
							</Route>
							<Route element={<Protected />}>
								<Route
									path="/submissions"
									element={<Submissions />}
								/>
								<Route
									path="/phone-verification"
									element={<PhoneVerification />}
								/>
							</Route>
							<Route element={<Guest />}>
								<Route path="/" element={<PriyoPlay />} />
								<Route path="/quiz" element={<PriyoPlay />} />
								<Route
									path="/quiz/:id"
									element={<FormPreview />}
								/>
							</Route>
							<Route
								path="/download"
								//element={<AppDownloadPage />}
							/>
							<Route path="/click" element={<Click />} />
							<Route
								path="*"
								element={
									<Navigate
										replace
										to={config.loginRedirect}
									/>
								}
							/>
						</Routes>
					</div>
				</div>
			</Router>
		</>
	);
}

export default App;
export { setLoading };
