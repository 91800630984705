import { Box } from "@mui/material";
import config from "../../config";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import clickApi from "../../api/click";

export default function Click() {
	const [searchParams] = useSearchParams();

	const fetchUrl = async (uuid) => {
		const res = await clickApi.getUrl(uuid);
		// window.location.href = res.data.url;
		if (res.success) {
			// console.log(res.data.url);
			window.location.href = res.data.url;
		}
	};
	useEffect(() => {
		const uuid = searchParams.get("next");
		if (uuid) {
			fetchUrl(uuid);
		} else {
			alert("No valid UUID found in the query parameter.");
		}
	}, [searchParams]);
	return (
		<div id="loading-animation" class="loading">
			<img src="/opengraph-image.png" alt="Priyo Logo" class="logo" />
			<p>Redirecting you, please wait...</p>
		</div>
	);
}
