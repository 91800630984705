const config = {
	baseUrl:
		process.env.REACT_APP_ENV === "production"
			? "https://play.priyo.com"
			: process.env.REACT_APP_ENV === "staging"
			? "https://play.priyolab.net"
			: "http://localhost:3000",
	authUrl:
		process.env.REACT_APP_ENV === "production"
			? "https://accounts.priyo.com"
			: process.env.REACT_APP_ENV === "staging"
			? "https://test-accounts.priyo.com"
			: "https://test-accounts.priyo.com",
	drawerWidth: 230,
	topbarHeight: 64,
	loginRedirect: "/",
	registerRedirect: "/",
	logoutRedirect: "/login",
};

export default config;
