import { createContext, useContext, useEffect, useState } from "react";
import { addTokenToLocalStorage, isTokenValid } from "../api/base";

const AuthContext = createContext();
export default function AuthContextProvider({ children }) {
	const [isAuthenticated, setIsAuthenticated] = useState(null);
	const handleStorageChange = () => {
		if (isTokenValid()) {
			setIsAuthenticated(true);
		} else {
			const token = localStorage.getItem("token");
			if (token) {
				console.log("Removing token");
				localStorage.removeItem("token");
			}
			setIsAuthenticated(false);
		}
	};
	useEffect(() => {
		if (!isAuthenticated) {
			const param = window.location.search;
			const urlParams = new URLSearchParams(param);
			const token = urlParams.get("q");
			if (token) {
				addTokenToLocalStorage(token);
				// Remove the 'q' parameter
				urlParams.delete("q");

				// Construct the new URL without the 'q' parameter
				const newUrl = `${window.location.pathname}${
					urlParams.toString() ? `?${urlParams.toString()}` : ""
				}`;

				// Update the browser's history
				window.history.replaceState({}, "", newUrl);
			}
		}
	}, [isAuthenticated]);

	useEffect(() => {
		handleStorageChange();
		window.addEventListener("storage", handleStorageChange);
		return () => {
			window.removeEventListener("storage", handleStorageChange);
		};
	}, []);
	return (
		<AuthContext.Provider value={{ isAuthenticated }}>
			{children}
		</AuthContext.Provider>
	);
}

export const useAuth = () => useContext(AuthContext);
