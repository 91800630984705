import { useEffect, useState } from "react";
import CardContainer from "../Containers/CardContainer";
import quizApi from "../../api/quiz";
import {
	Card,
	CardMedia,
	CardContent,
	Typography,
	CardActions,
	Button,
	Chip,
	Box,
	Container,
	Divider,
	useTheme,
} from "@mui/material";
import {
	Edit,
	Delete,
	AssignmentTurnedIn,
	Error,
	Input,
	Person,
	Close,
	AccessTime,
} from "@mui/icons-material";
import EventIcon from "@mui/icons-material/Event";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import TimerIcon from "@mui/icons-material/Timer";
import { useLocation, useNavigate } from "react-router-dom";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import ScheduleIcon from "@mui/icons-material/Schedule";
import BlockIcon from "@mui/icons-material/Block";
import Pluralize from "pluralize";
import { IoEnter } from "react-icons/io5";
import { format } from "date-fns";
import { LinearProgress, Tooltip } from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
function QuizTimeline({ quiz, currentTime }) {
	const startTime = new Date(quiz.start_time);
	const endTime = new Date(quiz.end_time);
	const totalDuration = endTime - startTime;
	const elapsed = currentTime - startTime;
	const progress = Math.max(
		0,
		Math.min(100, (elapsed / totalDuration) * 100)
	);

	let status = "Not Started";
	if (progress > 0 && progress < 100) status = "On going";
	if (progress === 100) status = "Ended";

	return (
		<Box>
			<Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
				<AccessTimeIcon
					color={progress === 100 ? "disabled" : "primary"}
					sx={{ mr: 1 }}
				/>
				<Typography
					variant="body2"
					color="text.primary"
					sx={{ fontWeight: "bold" }}
				>
					{status}
				</Typography>
			</Box>
			<Tooltip
				title={`${Math.round(progress)}% complete`}
				placement="top"
			>
				<LinearProgress
					variant="determinate"
					value={progress}
					sx={{
						height: 8,
						borderRadius: 4,
						// backgroundColor: "rgba(0,0,0,0.5)",
						"& .MuiLinearProgress-bar": {
							borderRadius: 4,
							backgroundColor:
								progress === 100
									? "text.disabled"
									: "primary.main",
						},
					}}
				/>
			</Tooltip>
			<Box
				sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}
			>
				<Typography variant="caption" color="text.secondary">
					{format(startTime, "MMM d, h:mm a")}
				</Typography>
				<Typography variant="caption" color="text.secondary">
					{format(endTime, "MMM d, h:mm a")}
				</Typography>
			</Box>
		</Box>
	);
}

export default function QuizCard({ quiz, currentTime, hasParticipated }) {
	const navigate = useNavigate();
	const location = useLocation();

	return (
		<Card
			sx={{
				// maxWidth: 345,
				height: "100%",
				display: "flex",
				flexDirection: "column",
			}}
		>
			<CardMedia
				component="img"
				loading="lazy"
				src={
					quiz.thumbnail ||
					"https://via.placeholder.com/300x140?text=No+Thumbnail"
				}
				alt={quiz.title}
				sx={{
					objectFit: "cover",
					width: "100%",
					// height: "140px",
					boxShadow: "0 0 20px rgba(0,0,0,0.1)",
					aspectRatio: "16/9",
				}}
			/>
			<CardContent
				sx={{
					paddingBottom: 0,
				}}
			>
				<Typography
					// gutterBottom
					variant="h5"
					component="div"
					noWrap
					align="left"
					className="font-bold"
				>
					{quiz.title}
				</Typography>
			</CardContent>
			<CardContent sx={{ flexGrow: 1 }}>
				<QuizTimeline quiz={quiz} currentTime={currentTime} />
			</CardContent>
			<CardActions sx={{ justifyContent: "space-between" }}>
				{quiz.start_time &&
					currentTime >= new Date(quiz.start_time) && (
						<Box className="flex items-center">
							<Person sx={{ fontSize: "1.25rem" }} />
							<Close sx={{ fontSize: "1.0rem" }} />
							<Typography variant="body1" className="ml-1">
								{quiz.submissions?.length ?? 0}
							</Typography>
						</Box>
					)}
				<Button
					size="small"
					className="ml-auto"
					endIcon={<Input />}
					onClick={() =>
						navigate("/quiz/" + quiz.id + location.search)
					}
				>
					{(quiz.end_time &&
						currentTime >= new Date(quiz.end_time)) ||
					!quiz.start_time ||
					currentTime < new Date(quiz.start_time) ||
					hasParticipated
						? "Enter"
						: "Participate"}
				</Button>
			</CardActions>
		</Card>
	);
}
