import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { PhoneNumberUtil } from "google-libphonenumber";
import authApi from "../../api/auth";
import config from "../../config/config";
import { setLoading } from "../../App";

const PhoneVerification = () => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [otp, setOtp] = useState("");
	const [otpSent, setOtpSent] = useState(false);
	const [verified, setVerified] = useState(false);
	const [error, setError] = useState("");
	const phoneUtil = PhoneNumberUtil.getInstance();
	const navigate = useNavigate();
	const sendOtp = async () => {
		try {
			const flag = phoneUtil.isValidNumber(
				phoneUtil.parseAndKeepRawInput(phoneNumber)
			);
			if (!flag) {
				setError("Please enter a valid phone number");
				return;
			}
			const res = await authApi.updateMobile(phoneNumber);
			if (res.success) {
				setOtpSent(true);
				setError("");
				console.log(`OTP sent to ${phoneNumber}`);
			} else {
				if (res.non_field_errors?.length > 0)
					setError(res.non_field_errors[0]);
				else setError("Please enter a valid phone number");
			}
		} catch (error) {
			setError("Please enter a valid phone number");
		}
	};

	const verifyOtp = async () => {
		if (otp.length === 6) {
			const res = await authApi.verifyMobile(phoneNumber, otp);
			if (res.success) {
				setVerified(true);
				setError("");
				console.log(`OTP verified for ${phoneNumber}`);
				navigate(config.loginRedirect);
			} else {
				setError("Invalid OTP. Please try again.");
			}
		} else {
			setError("Please enter a valid 6-digit OTP");
		}
	};

	useEffect(() => {
		setLoading(false);
	}, []);

	return (
		<div className="max-w-md mx-auto mt-10 p-8 bg-gray-50 rounded-md border shadow-lg">
			<div className="w-full flex flex-col items-center justify-center">
				<img
					src="https://i.postimg.cc/28DL0QQW/otp.png"
					className="w-20 h-20"
					alt="otp"
				/>
				<h2 className="text-3xl font-bold text-gray-800">
					Phone Verification
				</h2>
				<h2 className="text-sm mb-12 text-gray-600">
					Provide your number so that we can send you an OTP
				</h2>
			</div>
			{!verified ? (
				<div className="space-y-6">
					<div className="relative">
						<PhoneInput
							placeholder="Enter phone number"
							value={phoneNumber}
							onChange={setPhoneNumber}
							defaultCountry="bd"
							inputStyle={{
								width: "100%",
								padding: "1rem 2rem",
								paddingLeft: "1rem",
								border: "1px solid #e2e8f0",
								fontSize: "1rem",
							}}
							containerStyle={{
								width: "100%",
							}}
							className="mb-4"
						/>
					</div>
					{!otpSent ? (
						<button
							onClick={sendOtp}
							className="w-full bg-blue-600 text-white py-3 font-semibold transition duration-300 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
						>
							Send OTP
						</button>
					) : (
						<div className="space-y-4">
							<input
								type="text"
								value={otp}
								onChange={(e) => setOtp(e.target.value)}
								placeholder="Enter 6-digit OTP"
								className="w-full px-4 py-1 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
							/>
							<button
								onClick={verifyOtp}
								className="w-full bg-green-600 text-white py-3  font-semibold transition duration-300 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
							>
								Verify OTP
							</button>
						</div>
					)}
				</div>
			) : (
				<div
					className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded-lg"
					role="alert"
				>
					<p className="font-bold">Success</p>
					<p>Phone number verified successfully!</p>
				</div>
			)}
			{error && (
				<div
					className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded-lg"
					role="alert"
				>
					<p className="font-bold">Error</p>
					<p>{error}</p>
				</div>
			)}
		</div>
	);
};

export default PhoneVerification;
